import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import Papa from 'papaparse';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix for default marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// New component to update map center
function ChangeMapView({ center }) {
  const map = useMap();
  map.setView(center, 13);
  return null;
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log('Error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

const App = () => {
  const [wayPoints, setWayPoints] = useState([]);
  const [selectedWayPoint, setSelectedWayPoint] = useState(null);
  const [mapCenter, setMapCenter] = useState([0, 0]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(true); // Set default to true for dark mode

  useEffect(() => {
    console.log('App component mounted');
    const fetchData = async () => {
      try {
        // Add a timestamp to the URL to prevent caching
        const response = await fetch(`/bajaprt_2024_csv.csv?t=${new Date().getTime()}`);
        const csvData = await response.text();
        
        Papa.parse(csvData, {
          header: true,
          complete: (results) => {
            console.log('Parsed data:', results.data);
            // Sort waypoints alphabetically by WayPoint_Name
            const sortedWayPoints = results.data.sort((a, b) => 
              a.WayPoint_Name.localeCompare(b.WayPoint_Name)
            );
            setWayPoints(sortedWayPoints);
            
            // Set the first waypoint as selected and center the map on it
            if (sortedWayPoints.length > 0) {
              const firstWayPoint = sortedWayPoints[0];
              setSelectedWayPoint(firstWayPoint);
              const lat = parseCoordinate(firstWayPoint.Latitude);
              const lng = parseCoordinate(firstWayPoint.Longitude);
              setMapCenter([lat, lng]);
            }
            
            setIsLoading(false);
          },
          error: (error) => {
            console.error('Error parsing CSV:', error);
            setIsLoading(false);
          },
        });
      } catch (error) {
        console.error('Error fetching CSV:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSelectWayPoint = (event) => {
    const selected = wayPoints.find(wp => wp.WayPoint_Name === event.target.value);
    console.log('Selected waypoint:', selected);
    setSelectedWayPoint(selected);
    if (selected) {
      const lat = parseCoordinate(selected.Latitude);
      const lng = parseCoordinate(selected.Longitude);
      setMapCenter([lat, lng]);
    }
  };

  const parseCoordinate = (coord) => {
    const [degrees, minutes] = coord.split('°');
    const [minuteValue, direction] = minutes.split("'");
    return (parseFloat(degrees) + parseFloat(minuteValue) / 60) * (direction === 'N' || direction === 'E' ? 1 : -1);
  };

  const getGoogleMapsUrl = () => {
    if (selectedWayPoint) {
      const lat = parseCoordinate(selectedWayPoint.Latitude);
      const lng = parseCoordinate(selectedWayPoint.Longitude);
      return `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
    }
    return '#';
  };

  const getWazeUrl = () => {
    if (selectedWayPoint) {
      const lat = parseCoordinate(selectedWayPoint.Latitude);
      const lng = parseCoordinate(selectedWayPoint.Longitude);
      return `https://www.waze.com/ul?ll=${lat},${lng}&navigate=yes`;
    }
    return '#';
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      fontFamily: 'Arial, sans-serif',
      backgroundColor: isDarkMode ? '#121212' : '#ffffff',
      color: isDarkMode ? '#ffffff' : '#000000',
    },
    header: {
      padding: '20px',
      backgroundColor: isDarkMode ? '#1e1e1e' : '#f8f9fa',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
    logoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '20px',
    },
    logo: {
      width: 'auto',
      height: '50px',
    },
    title: {
      fontSize: '24px',
      fontWeight: 'bold',
      margin: '0',
      color: isDarkMode ? '#ffffff' : '#000000',
    },
    selectContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    select: {
      width: '60%',
      padding: '10px',
      fontSize: '16px',
      borderRadius: '5px',
      border: '1px solid #ced4da',
      backgroundColor: isDarkMode ? '#2c2c2c' : '#ffffff',
      color: isDarkMode ? '#ffffff' : '#000000',
    },
    mapContainer: {
      flex: 1,
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      padding: '20px',
      backgroundColor: isDarkMode ? '#1e1e1e' : '#f8f9fa',
    },
    button: {
      padding: '10px 20px',
      fontSize: '16px',
      color: '#fff',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
    },
    googleButton: {
      backgroundColor: '#4285F4',
    },
    wazeButton: {
      backgroundColor: '#33ccff',
    },
    infoContainer: {
      padding: '20px',
      backgroundColor: isDarkMode ? '#1e1e1e' : '#f8f9fa',
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 20px',
      backgroundColor: isDarkMode ? '#1e1e1e' : '#f8f9fa',
      color: isDarkMode ? '#ffffff' : '#000000',
      fontSize: '14px',
    },
    toggleButton: {
      padding: '5px 10px',
      backgroundColor: isDarkMode ? '#ffffff' : '#000000',
      color: isDarkMode ? '#000000' : '#ffffff',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
    },
  };

  if (isLoading) {
    return <div style={styles.container}>Loading...</div>;
  }

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <div style={styles.logoContainer}>
          <img src="/logo2.png" alt="BAJA PORTALEGRE" style={styles.logo} />
          <img src="/logo3.png" alt="VOSTPT" style={styles.logo} />
        </div>
        <div style={styles.selectContainer}>
          <select onChange={handleSelectWayPoint} value={selectedWayPoint?.WayPoint_Name || ''} style={styles.select}>
            <option value="">Select a waypoint</option>
            {wayPoints.map((wp) => (
              <option key={wp.WayPoint} value={wp.WayPoint_Name}>
                {wp.WayPoint_Name}
              </option>
            ))}
          </select>
        </div>
      </header>

      <div style={styles.mapContainer}>
        <MapContainer center={mapCenter} zoom={13} style={{ height: '100%' }}>
          <TileLayer 
            url={isDarkMode 
              ? "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
              : "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            } 
          />
          <ChangeMapView center={mapCenter} />
          {selectedWayPoint && (
            <Marker position={[parseCoordinate(selectedWayPoint.Latitude), parseCoordinate(selectedWayPoint.Longitude)]}>
              <Popup>{selectedWayPoint.WayPoint_Name}</Popup>
            </Marker>
          )}
        </MapContainer>
      </div>

      {selectedWayPoint && (
        <div style={styles.buttonContainer}>
          <a href={getGoogleMapsUrl()} target="_blank" rel="noopener noreferrer">
            <button style={{...styles.button, ...styles.googleButton}}>GMaps</button>
          </a>
          <a href={getWazeUrl()} target="_blank" rel="noopener noreferrer">
            <button style={{...styles.button, ...styles.wazeButton}}>Waze</button>
          </a>
        </div>
      )}

      {selectedWayPoint && (
        <div style={styles.infoContainer}>
          <h2>INFORMAÇÕES</h2>
          <p><strong>WayPoint:</strong> {selectedWayPoint.WayPoint_Name}</p>
          <p><strong>Latitude:</strong> {selectedWayPoint.Latitude}</p>
          <p><strong>Longitude:</strong> {selectedWayPoint.Longitude}</p>
          <p><strong>Local:</strong> {selectedWayPoint.Local}</p>
          <p><strong>Observações:</strong> {selectedWayPoint.Obs}</p>
        </div>
      )}

      <footer style={styles.footer}>
        <span>© VOST Portugal 2024</span>
        <button 
          style={styles.toggleButton} 
          onClick={() => setIsDarkMode(!isDarkMode)}
        >
          {isDarkMode ? 'Light Mode' : 'Dark Mode'}
        </button>
      </footer>
    </div>
  );
};

const RenderApp = () => (
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
);

export default RenderApp;